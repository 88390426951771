const LOCAL_STORAGE_KEY = 'ccc';

export const TYPE_SUPER_TOKEN = 'TYPE_SUPER_TOKEN';
export const TYPE_LOG_IN = 'TYPE_LOG_IN';
export const TYPE_LOG_OUT = 'TYPE_LOG_OUT';
export const TYPE_SET_PLAYLIST = 'TYPE_SET_PLAYLIST';
export const TYPE_SET_PLAYLIST_ONCHANGE = 'TYPE_SET_PLAYLIST_ONCHANGE';
export const TYPE_SET_TRACK = 'TYPE_SET_TRACK';
export const TYPE_TOGGLE_PLAY_PAUSE = 'TYPE_TOGGLE_PLAY_PAUSE';
export const TYPE_SET_PLAYER_PLAY = 'TYPE_SET_PLAYER_PLAY';
export const TYPE_SET_PLAYER_PAUSE = 'TYPE_SET_PLAYER_PAUSE';
export const TYPE_SET_VOLUME = 'TYPE_SET_VOLUME';
export const TYPE_SET_MUTE = 'TYPE_SET_MUTE';
export const TYPE_SET_UNMUTE = 'TYPE_SET_UNMUTE';
export const TYPE_TOGGLE_MINI_PLAYER = 'TYPE_TOGGLE_MINI_PLAYER';
export const TYPE_LOCK_INTERRUPT_MINI_PLAYER = 'TYPE_LOCK_INTERRUPT_MINI_PLAYER';
export const TYPE_UNLOCK_INTERRUPT_MINI_PLAYER = 'TYPE_UNLOCK_INTERRUPT_MINI_PLAYER';
export const TYPE_HIDE_MINI_PLAYER = 'TYPE_HIDE_MINI_PLAYER';
export const TYPE_SET_SEEKBAR_POS = 'TYPE_SET_SEEKBAR_POS';
export const TYPE_SET_SEEKBAR_POS_FOR_PLAYER = 'TYPE_SET_SEEKBAR_POS_FOR_PLAYER';
export const TYPE_LOCK_SEEKBAR_MUTEX = 'TYPE_LOCK_SEEKBAR_MUTEX';
export const TYPE_TOGGLE_MOBILE_PLAYER = 'TYPE_TOGGLE_MOBILE_PLAYER';
export const TYPE_UNLOCK_INTERRUPT_MOBILE_PLAYER = 'TYPE_UNLOCK_INTERRUPT_MOBILE_PLAYER';
export const TYPE_LOCK_INTERRUPT_MOBILE_PLAYER = 'TYPE_LOCK_INTERRUPT_MOBILE_PLAYER';
export const TYPE_SET_LANGUAGE = 'TYPE_SET_LANGUAGE';
// Audio Play state CONSTs
export const STATE_PLAYING = 0;
export const STATE_PAUSED = 1;
export const STATE_BUFFERING = 2;
export const STATE_STOPPED = 3;

// Quiz actions

export const GET_QUIZ_DATA = 'GET_QUIZ_DATA';
export const GET_CHAPTER_ID_FROM_PARAMS = 'GET_CHAPTER_ID_FROM_PARAMS';
export const RESET_CURRENT_CHAPTER = 'RESET_CURRENT_CHAPTER';
export const GET_QUIZ_QUESTIONS_BY_CHAPTER_ID = 'GET_QUIZ_QUESTIONS_BY_CHAPTER_ID';
export const RESET_QUIZ_QUESTIONS = 'RESET_QUIZ_QUESTIONS';
export const POST_QUIZ_DATA = 'POST_QUIZ_DATA';
export const RESET_QUIZ_DATA = 'RESET_QUIZ_DATA';



const initState = {
  loggedIn: false,
  language: 'eng', // oneOf(['eng','esp'])
  langChoices: ['eng', 'esp'],
  flagImages: {
    eng: 'https://mk0childrensceno0ux5.kinstacdn.com/wp-content/plugins/sitepress-multilingual-cms/res/flags/en.png',
    esp: 'https://mk0childrensceno0ux5.kinstacdn.com/wp-content/plugins/sitepress-multilingual-cms/res/flags/es.png',
  },
  auth: {
    first_name: 'James',
    last_name: 'Wilson',
    username: 'james',
    email: 'james@webpoint.com',
    token: '',
    avatar: '',
    userId: '',
    rcpUserId: '',
    memberships: [],
    payments: [],
  },
  audio: {
    playlist: [],
    currentTrackId: '',
    currentTrackGroupId: '',
    currentTrackIndex: -2,
    currentBookTitle: '',
    playState: STATE_STOPPED,
    seekBarPosition: 0,
    seekBarMutex: false,
    volume: 100,
    mute: false,
    currentBook: {},
  },
  miniPlayer: {
    show: false,
    interrupt: false,
  },
  mobilePlayer: {
    show: false,
    interrupt: false,
  },
  quiz: {
    error: {},
    quizData: [],
    currentQuiz: {},
  },
  currentChapter: {
    chapter_id: '',
    chapter_title: '',
    chapter_slug: '',
    book_id: '',
    lms_chapter_id: '',
    lms_book_id: '',
    lms_quiz_id: '',
  },
  currentQuizQuestions: {
    quiz_description: '',
    questions: [],
    language: '',
  },
  currentQuiz: {
    posted: '',
  },
  super_token: '',
};

export default (state = initState, action) => {
  switch (action.type) {
    case TYPE_SUPER_TOKEN:
      return {
        ...state,
        super_token: action.payload,
      };
    case TYPE_LOG_IN:
      return {
        ...state,
        auth: {
          ...state.auth,
          first_name: action.payload.user_display_name,
          last_name: '',
          username: action.payload.user_nicename,
          email: action.payload.user_email,
          token: action.payload.token,
          avatar: '',
          userId: action.payload.user_id,
          rcpUserId: action.payload.rcp_user_id,
          memberships: action.payload.memberships || [],
          payments: action.payload.payments || [],
        },
        loggedIn: true,
      };
    case TYPE_LOG_OUT:
      return initState;
    case TYPE_SET_PLAYLIST:
      return {
        ...state,
        audio: {
          ...state.audio,
          playlist: action.payload.playlist,
          currentTrackId: action.payload.track,
          currentTrackGroupId: action.payload.trackGroup,
          currentTrackIndex: action.payload.trackIndex,
          currentBookTitle: action.payload.bookTitle,
          currentBook: action.payload.book,
          seekBarPosition: action.payload.seekBarPosition || 0,
        },
      };
    case TYPE_SET_PLAYLIST_ONCHANGE:
      return {
        ...state,
        audio: {
          ...state.audio,
          playlist: action.payload.playlist,
        },
      };
    case TYPE_SET_TRACK:
      return {
        ...state,
        audio: {
          ...state.audio,
          currentTrackId: action.payload.track,
          currentTrackIndex: action.payload.trackIndex,
          seekBarPosition: action.payload.seekBarPosition || 0,
        },
      };
    case TYPE_TOGGLE_PLAY_PAUSE:
      return {
        ...state,
        audio: {
          ...state.audio,
          playState: action.payload,
        },
      };
    case TYPE_TOGGLE_MINI_PLAYER:
      return {
        ...state,
        miniPlayer: {
          ...state.miniPlayer,
          show: action.payload,
        },
      };
    case TYPE_LOCK_INTERRUPT_MINI_PLAYER:
      return {
        ...state,
        miniPlayer: {
          ...state.miniPlayer,
          interrupt: true,
        },
      };
    case TYPE_UNLOCK_INTERRUPT_MINI_PLAYER:
      return {
        ...state,
        miniPlayer: {
          ...state.miniPlayer,
          interrupt: false,
        },
      };
    case TYPE_LOCK_INTERRUPT_MOBILE_PLAYER:
      return {
        ...state,
        mobilePlayer: {
          ...state.mobilePlayer,
          interrupt: true,
        },
      };
    case TYPE_UNLOCK_INTERRUPT_MOBILE_PLAYER:
      return {
        ...state,
        mobilePlayer: {
          ...state.mobilePlayer,
          interrupt: false,
        },
      };
    case TYPE_SET_PLAYER_PLAY:
      return {
        ...state,
        audio: {
          ...state.audio,
          playState: STATE_PLAYING,
        },
      };
    case TYPE_SET_VOLUME:
      return {
        ...state,
        audio: {
          ...state.audio,
          volume: action.payload,
        },
      };
    case TYPE_SET_MUTE:
      return {
        ...state,
        audio: {
          ...state.audio,
          mute: true,
        },
      };
    case TYPE_SET_UNMUTE:
      return {
        ...state,
        audio: {
          ...state.audio,
          mute: false,
        },
      };
    case TYPE_SET_PLAYER_PAUSE:
      return {
        ...state,
        audio: {
          ...state.audio,
          playState: false,
        },
      };
    case TYPE_SET_SEEKBAR_POS:
      return {
        ...state,
        audio: {
          ...state.audio,
          seekBarPosition: action.payload,
        },
      };
    case TYPE_SET_SEEKBAR_POS_FOR_PLAYER:
      return {
        ...state,
        audio: {
          ...state.audio,
          seekBarPosition: action.payload.position,
          seekBarMutex: true,
        },
      };
    case TYPE_LOCK_SEEKBAR_MUTEX:
      return {
        ...state,
        audio: {
          ...state.audio,
          seekBarMutex: false,
        },
      };
    case TYPE_TOGGLE_MOBILE_PLAYER:
      return {
        ...state,
        mobilePlayer: {
          ...state.mobilePlayer,
          show: action.payload,
        },
      };
    case TYPE_SET_LANGUAGE:
      // destroy
      return {
        ...state,
        language: action.payload,
        
      };

    case GET_QUIZ_DATA:
      return {
        ...state,
        quiz: {
          ...state.quiz,
          quizData: action.payload,
        },
      };

    case GET_CHAPTER_ID_FROM_PARAMS:
      return {
        ...state,
        currentChapter: action.payload,
      };

    case RESET_CURRENT_CHAPTER:
      return {
        ...state,
        currentChapter: initState.currentChapter,
      };

    case GET_QUIZ_QUESTIONS_BY_CHAPTER_ID:
      return {
        ...state,
        currentQuizQuestions: action.payload,
      };
    case RESET_QUIZ_QUESTIONS:
      return {
        ...state,
        currentQuizQuestions: initState.currentQuizQuestions,
      };
    case RESET_QUIZ_DATA:
      return {
        ...state,
        currentQuiz: {
          posted: '',
        },
      };
    case POST_QUIZ_DATA:
      return {
        ...state,
        currentQuiz: {
          posted: action.payload,
        },
      };
    default:
      return state;
  }
};
