import GlobalAudioWrapper from '../components/audioPlayer/globalWrapper';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import React from 'react';
import getStore from './store';

const StoreProvider = ({ children }) => {
  const { store, persistor } = getStore();

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
        <GlobalAudioWrapper />
      </PersistGate>
    </Provider>
  );
};

export default StoreProvider;
